@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"), url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"), url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"), url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

body,
html {
  font-family: Poppins-Regular;
}

#root {
  height: 100vh;
  overflow: hidden;
}


@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 0px #fff;
  }

  to {
    text-shadow: 0 0 30px #ffffff;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 0px #fff;
  }

  to {
    text-shadow: 0 0 30px #ffffff;
  }
}

.glow {
  color: #fff;
  animation: glow 2s ease-in-out infinite alternate;
}

.slick-slide {
  padding-right: 10px
}

.scrollDashboard {
  overflow-y: scroll;
  /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollDashboard::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollDashboard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dddddd;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #899B3C;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #255300;
}

@media screen and (min-width: 900px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  html {
    font-size: 14px;
  }

  h5 {
    font-size: 1rem;
  }

  .MuiDialog-root .MuiPaper-root {
    margin: 8px;
    width: 100%;
  }
}


.MuiMenuItem-root:hover {
  background-color: #8a9b3c7f!important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #8a9b3c44!important;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: #8a9b3ca6!important;
}